<template>
  <div style="overflow: hidden">
    <img :class="[isFullImg ? 'fullImg' : '']" :src="propValue.file_down_path" />
  </div>
</template>

<script>
export default {
  props: {
    propValue: {
      type: Object,
      require: true,
      default: {},
    },
    element: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isFullImg: function() {
      return this.element.style.backgroundSize === 'contain';
    }
  }
};
</script>

<style lang="scss" scoped>
.fullImg {
  width: 100%;
  height: 100%;
}
</style>
